import { isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit';

import { createAsyncActionErrorToast, createRequestErrorToast } from './utils';

export const displayErrorForRejectedThunkMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (!isRejectedWithValue(action)) {
    return next(action);
  }

  const url = (action.meta as { baseQueryMeta?: { request?: { url?: string } } })?.baseQueryMeta?.request?.url;
  if (url) {
    const payload = action.payload as { error?: string; data?: { message: string }; status?: number };
    let message = payload.error || payload.data?.message || 'Unknown';
    if (message.length > 193) {
      message = `${message.slice(0, 190)}...`;
    }
    const shortUrl = url.split('?')[0];
    createRequestErrorToast(shortUrl, message, payload.status);
    return next(action);
  }

  const actionMessage = JSON.stringify(action.payload);
  createAsyncActionErrorToast(actionMessage);

  return next(action);
};

import React from 'react';

import { Spinner } from '../../../../common/components/Spinner';
import { PortCalls } from '../../../../store/apis/ais-api';
import { SelectedEvent } from '../../slice';
import { PortCallItem } from './PortCallItem';
import { ExclamationCircleIcon } from '../../../../common/components/icons/ExclamationCircleIcon';

type Props = {
  portCalls?: PortCalls[];
  isFetching: boolean;
  selectedEvent: SelectedEvent;
  isError: boolean;
  error: any;
};

export const PortCallList: React.FC<Props> = ({ portCalls, isFetching, selectedEvent, isError, error }) => {
  return (
    <div className="PortCallList">
      {isFetching ? (
        <div className="flex items-center justify-center h-full">
          <Spinner className="my-10 h-6 w-6" />
        </div>
      ) : isError ? (
        <div className="h-full text-white mt-6 text-center">
          <div className="flex items-center justify-center">
            <ExclamationCircleIcon className="h-10 w-10 mb-2" />
          </div>
          {error?.data?.message}
        </div>
      ) : portCalls?.length === 0 ? (
        <div className="h-full text-white mt-6 text-center">
          <div className="flex items-center justify-center">
            <ExclamationCircleIcon className="h-10 w-10 mb-2" />
          </div>{' '}
          No port calls found during selected time range
        </div>
      ) : (
        portCalls?.map((portCall) => {
          const selected = portCall.arrival_time === selectedEvent?.id && selectedEvent?.type === 'port-calls';
          return <PortCallItem key={portCall.arrival_time} portCall={portCall} selected={selected} />;
        })
      )}
    </div>
  );
};
